import { ActivityHome, page404 } from "@/router/common.router";

const ActivityBaseInfo = () => import("@/components/Activity/Children/BaseInfo.vue");
const ActivityBrandStore = () => import("@/components/Activity/Children/BrandStore.vue");
const ActivityMarketing = () => import("@/components/Activity/Children/Marketing.vue");
const ActivitySettings = () => import("@/components/Activity/Children/Settings.vue");
const ActivityStatistics = () => import("@/components/Activity/Children/Statistics.vue");
const ActivityGuide = () => import("@/components/Activity/Children/Guide.vue");
const ActivityFinance = () => import("@/components/Activity/Children/Finance.vue");
const ActivityAdmin = () => import("@/components/Activity/Children/Admin.vue");
const ActivityAdminInfo = () => import("@/components/Activity/Children/AdminInfo.vue");

export default [
  {
    path: "/activity/baseinfo",
    component: ActivityHome,
    children: [
      {
        path: "/activity/baseinfo/:activityID",
        component: ActivityBaseInfo,
        meta: { title: "基本设置" }
      },
      {
        path: "/activity/brandstore/:activityID",
        component: ActivityBrandStore,
        meta: { title: "品牌门店设置" }
      },
      {
        path: "/activity/marketing/:activityID",
        component: ActivityMarketing,
        meta: { title: "营销设置" }
      },
      {
        path: "/activity/settings/:activityID",
        component: ActivitySettings,
        meta: { title: "页面配置" }
      },
      {
        path: "/activity/statistics/:activityID",
        component: ActivityStatistics,
        meta: { title: "数据统计" }
      },
      {
        path: "/activity/guide/:activityID",
        component: ActivityGuide,
        meta: { title: "导购员设置" }
      },
      {
        path: "/activity/finance/:activityID",
        component: ActivityFinance,
        meta: { title: "财务" }
      },
      {
        path: "/activity/userset/:activityID",
        component: ActivityAdmin,
        meta: { title: "管理员设置" }
      },
      {
        path: "/activity/adminInfo/:activityID",
        component: ActivityAdminInfo,
        meta: { title: "管理员设置" }
      },
      { path: "/config/404", component: page404, meta: { title: "迷路了" } }
    ]
  }
];
