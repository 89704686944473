import Vue from "vue";
import { formatImage } from '@/assets/utils';

//金额保留小数点2位
const formatMoney = (value) => {
  if(value === 0){ 
    return 0;
  } else if(!value){
    return '--';
  } else if(!Number(value)){
    return '--';
  }
  return (value/100).toFixed(2);
};

Vue.filter('formatMoney',formatMoney);
Vue.filter('formatImage',formatImage);