import { LayoutHome, page404 } from "@/router/common.router";

const Activitylist = () => import("@/components/Activity/ActivityList.vue");
const ActivityAdd = () => import("@/components/Activity/ActivityAdd.vue");
const rightsMg = () => import("@/pages/rightsMg.vue");
const settingsMg = () => import("@/pages/settingsMg.vue");
const admin = () => import("@/pages/admin.vue");
const editadmin = () => import("@/pages/admin/adminInfo.vue");

export default [
  {
    path: "/",
    component: LayoutHome,
    children: [
      {
        path: "/activity",
        component: Activitylist,
        meta: { title: "活动列表" }
      },
      {
        path: "/activity/add",
        component: ActivityAdd,
        meta: { title: "添加活动" }
      },
      {
        path: "/config/settings",
        component: settingsMg,
        meta: { title: "系统设置" }
      },
      {
        path: "/config/rights",
        component: rightsMg,
        meta: { title: "权限管理" }
      },
      {
        path: "/config/admin",
        component: admin,
        meta: { title: "管理员列表" }
      },
      {
        path: "/config/adminInfo",
        component: editadmin,
        meta: { title: "管理员编辑" }
      },
      { path: "/config/404", component: page404, meta: { title: "迷路了" } }
    ]
  }
];
