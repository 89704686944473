<template>
  <div id="app" class="h100p">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "youxiang"
};
</script>
