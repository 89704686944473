import Vue from "vue";
import Router from "vue-router";
import NProgress from "nprogress";
import IndexRouter from "./modules/Index.router";
import ActivityRouter from "./modules/Activity.router";
import { getSession } from "@/assets/utils.js";

Vue.use(Router);

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

const config = {
  mode: "hash" // history
};

const Login = () => import("@/pages/Login.vue");

const router = new Router({
  mode: config.mode, // history
  routes: [
    { path: "/", redirect: "/activity" },
    ...IndexRouter,
    ...ActivityRouter,
    { path: "/login", component: Login, meta: { title: "请登录~" } },
    { path: "*", redirect: "/404" }
  ]
});

router.beforeEach((to, from, next) => {
  NProgress.start();
  if (!to.path.includes("login")) {
    // 没有token token失效
    if (!getSession("yxtoken")) {
      next("/login");
    } else {
      next();
    }
  } else {
    next();
  }
});

router.afterEach(() => {
  // 成功进入页面后结束进度条
  NProgress.done();
});

export default router;
